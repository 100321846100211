<div class="votes-section">
  <h1 class="votes-title">
    {{ "APP.VOTING.AFTER_CREATION.ARE_YOU_READY_TO_WIN" | translate }}
  </h1>
  <div class="votes-content">
    <div class="bullet-point"><span>1</span></div>
    <div class="description">
      {{ "APP.VOTING.AFTER_CREATION.INVITE_ALL_FRIENDS" | translate }}
    </div>
    <div class="bullet-point"><span>2</span></div>
    <div class="description winning-category">
      {{ "APP.VOTING.AFTER_CREATION.MOST_VIEWS_CATEGORY_CHOSEN" | translate }}
    </div>
    <div class="description remove-margin">
      {{ "APP.VOTING.AFTER_CREATION.MOST_VIEWS_COLLECT" | translate }}
    </div>
    <div class="voting-link">
      <form [formGroup]="form">
        <mat-form-field appearance="outline" class="w-100 copy-button">
          <input
            type="text"
            matInput
            formControlName="votingLink"
            [readonly]="true"
          />
          <div matSuffix class="icon" (click)="copyVotingLink()">
            <i class="far fa-copy icon-copy"></i>
          </div>
        </mat-form-field>
      </form>
    </div>

    <div class="description remove-margin">
      {{ "APP.VOTING.AFTER_CREATION.VOTING_STARTS_AT" | translate }}
    </div>
    <div class="voting-time description">
      {{ "APP.VOTING.AFTER_CREATION.FIXED_TIME" | translate }}
    </div>
    <div class="description">
      {{ "APP.VOTING.AFTER_CREATION.GET_NOTIFICATIONS" | translate }}
    </div>
    <button
      class="btn btn-primary-party-page subscribe-button"
      (click)="onSubscribe()"
    >
      {{ "APP.VOTING.AFTER_CREATION.SUBSCRIBE" | translate }}
    </button>
  </div>
</div>
