import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/shared/notification.service';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-most-votes',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    FormsModules,
    MatIconModule,
    ReactiveFormsModule,
  ],
  providers: [NotificationService],
  templateUrl: './most-votes.component.html',
  styleUrl: './most-votes.component.scss',
})
export class MostVotesComponent implements OnInit {
  @Input() event?: Event;
  form: FormGroup;
  votingLink?: string;
  constructor(
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.form = this.fb.group({
      votingLink: [''],
    });
  }

  ngOnInit(): void {
    if (this.event) {
      this.votingLink = this.event?.getVotingLink();
      this.form.patchValue({
        votingLink: this.votingLink,
      });
    }
  }

  copyVotingLink() {
    if (this.votingLink) {
      this.clipboard.copy(this.votingLink);
      this.notificationService.success(
        this.translateService.instant(
          'APP.AFFILIATE_PRODUCTS_PAGE_LIST.SUCCESS.LINK_COPIED',
        ),
      );
    }
  }

  onSubscribe() {
    window.open(
      'https://www.whatsapp.com/channel/0029VaWGiyGKGGGPV0wFYV26',
      '_blank',
    );
  }
}
